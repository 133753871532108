import ButtonMoreView from './ButtonMoreView';
import { Tit } from './Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import React, { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

interface ThumbnailProps {
  thumbs: ThumbnailData[];
}

export interface ThumbnailData {
  id: string | number;
  img: ImgHTMLAttributes<any>['src'];
  title: string | undefined;
  dec: string;
  tip?: string;
  more?: string;
}
const ThumbnailLayout = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;

  .list-item {
    width: 50%;
    padding: 0 16px;
    margin-bottom: 64px;

    .img-box {
      padding-top: 56.62%;
      position: relative;

      &._btn {
        margin-bottom: 28px;
      }
      ${ButtonMoreView} {
        position: absolute;
        right: 0;
        bottom: -28px;
        max-width: 256px;
        width: 100%;
      }
    }
    .tip {
      color: #1cabe2;
      margin-top: 24px;
    }
  }
  ${breakpoint(`mobile`)} {
    .list-item {
      width: 100%;
      margin-bottom: 72px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ThumbnailBody = styled.div`
  padding: 32px;
  background: #fff;

  .dec {
    margin-top: 16px;
    line-height: 2;

    strong {
      display: block;
      width: 100%;
      margin: 32px 0 16px 0;
    }
  }

  ${breakpoint(`tablet`)} {
    padding: 0;
    padding-top: 16px;
  }
`;

const ThumbnailFlex = styled<FC<ThumbnailProps>>(({ thumbs }) => (
  <>
    <ThumbnailLayout>
      {thumbs.map((thumb) => (
        <li className="list-item">
          <div
            className={thumb.more ? `_btn img-box` : `img-box`}
            css={`
              background-image: url(${thumb.img});
            `}
          >
            {thumb.more ? (
              <ButtonMoreView mode="sky" href={thumb.more} target="_blank">
                자세히 보기
              </ButtonMoreView>
            ) : (
              ``
            )}
          </div>
          <ThumbnailBody className="dec-wrap">
            {thumb.title && (
              <Tit size="s4-1" color="sky">
                {thumb.title}
              </Tit>
            )}
            <p
              className="dec"
              dangerouslySetInnerHTML={{ __html: thumb.dec }}
            />
            {thumb.tip ? <p className="tip">{thumb.tip}</p> : ``}
          </ThumbnailBody>
        </li>
      ))}
    </ThumbnailLayout>
  </>
))``;
export default ThumbnailFlex;
